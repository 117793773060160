import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/hero"
import Content from "../components/content"
import SEO from "../components/seo"
import ArticleList from "../components/article-list"

const Page = ({ data }) => (
  <>
    <SEO title="Articles" />
    <Hero title={data.strapiPage.title} image={data.strapiPage.image} />
    <Content content={data.strapiPage.content} />
    <ArticleList />
  </>
)

export default Page

export const query = graphql`
  query {
    strapiPage(slug: { eq: "articles" }) {
      id
      title
      content
      image {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
